import React from "react"
import SpacingImg from '../images/spacing.svg'


const Spacing = ({ children }) => {

  return (
    <>
      <div className="spacing">
        <SpacingImg />
    </div>
    </>
  )
}


export default Spacing