import React, {useRef} from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Spacing from "../components/spacing"
import SanValentinoMin from "../components/sanvalentinoMin"
import Form from "../components/form"


import Bootstrap from '../css/bootstrap.grid.css'
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) 

const IndexPage = () => {

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  return(
  <Layout>
    <SEO title="Home" />
    <section className="aboveFold custom-container">
        <div>
            <h1>
                Oltre 35 anni di <br /><span>Sapori, Passione e Tradizione</span>
            </h1>
            <a onClick={executeScroll} className="button contact-scroll">Prenota Ora</a>
        </div>
    </section>
    <Spacing />
    <section className="eventi custom-container">
        <h2>Eventi</h2>
        <div className="row">
          <div className="offset-lg-3 col-lg-6">
            <Link to="/cena-san-valentino-2020">
              <SanValentinoMin />
            </Link>
          </div>
        </div>
    </section>
    <Spacing />
    <section className="storia custom-container">
        <h2>La Nostra Storia</h2>
        <div className="container">
            <p>Nel 1969 Vincenzo e Chiarina aprono un’osteria, offrendo prodotti tradizionali e piatti umili, profumati dai sapori della terra molisana e accompagnati da vini locali.</p>
            <p>Con l'intenzione di portare avanti queste idee <strong>nel 1981</strong> nasce il <strong>Ristorante Il Cacciatore,</strong>  che va a fondere vecchi sapori con un tocco di modernità, in un ambiente familiare e accogliente.</p>
        </div>
    </section>
    <Spacing />
    <section id="sectionContatti" ref={myRef} className="contatti custom-container">
        <h2>Contattaci o vieni a trovarci</h2>
        <div className="row">
            <div className="col-md-4">
                <ul>
                        <li className="titolo_contatti">Orari</li>
                        <li>Siamo aperti dal Martedì alla Domenica <br />12:00 - 14:30 <span></span> 19:00 - 22:30 </li>
                        <li className="titolo_contatti">Email</li>
                        <li>ristoranteilcacciatore@gmail.com</li>
                        <li className="titolo_contatti">Telefono</li>
                        <li>0874799694</li>
                        <li className="titolo_contatti">Indirizzo</li>
                        <li>C.da San Marco 18, <br />Cercemaggiore (CB)</li>
                </ul>
            </div>
            <div className="col-md-8">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11964.447775077444!2d14.7095945!3d41.4367926!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x73401424872c2da1!2sRistorante%20Il%20Cacciatore!5e0!3m2!1sit!2sit!4v1571245309493!5m2!1sit!2sit" height="450" frameBorder="0" allowFullScreen=""></iframe>
            </div>
        </div>
        <div className="container form">
                <Form formTitle="Scrivici" />
        </div>
    </section>
  </Layout>
)}

export default IndexPage
